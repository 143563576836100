import { lazy } from "react";

export const ShippingCalculatorFormLazy = lazy(() =>
  import("../../core/shipping-calculator/ShippingCalculatorForm.js")
);
export const SignupLazy = lazy(() => import("../../auth/Signup/Signup.js"));
export const SigninLazy = lazy(() => import("../../auth/Signin/Signin.js"));
export const ForgotLazy = lazy(() => import("../../auth/Forgot/Forgot.js"));
export const ResetLazy = lazy(() => import("../../auth/Reset/Reset.js"));
export const PricingPageLazy = lazy(() =>
  import("../../payments/PricingPage.js")
);
