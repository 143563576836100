import React, { useState, useContext } from "react";
import { withRouter } from "react-router-dom";
import axios from "axios";
import { sessionContext } from "../App.js";
import { env } from "../env.js";
import {
  TextField,
  Button,
  Typography,
  Box,
  Paper,
  // Link,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Alert,
  Grid,
} from "@mui/material";
import { signout } from "../auth/helpers.js";
import reachship_logo from "../assets/images/reachship_logo_transparent_dark.svg";
import elextensions_caption from "../assets/images/elextensions_caption.png";

const DeactivatedUser = ({ history }) => {
  // Get user session details.
  const sess = useContext(sessionContext);
  const [session, setSession] = sess;
  const [cancelDeleteDialog, setCancelDeleteDialog] = useState("close");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [customAlertInDialog, setCustomAlertInDialog] = useState("");

  const handleCancelAccountDeletion = () => {
    if (confirmPassword === "") {
      setCustomAlertInDialog(
        <Alert
          severity="error"
          className="alert"
          onClose={() => {
            setCustomAlertInDialog("");
          }}
        >
          Please enter your password!
        </Alert>
      );
    } else {
      axios({
        withCredentials: true,
        method: "POST",
        url: `${env.api_url}/user/cancel-user-deletion`,
        data: { confirmPassword },
      })
        .then((response) => {
          setCustomAlertInDialog(
            <Alert
              severity="success"
              className="alert"
              onClose={() => {
                setCustomAlertInDialog("");
              }}
            >
              {response.data.message}
            </Alert>
          );
          setTimeout(async () => {
            const deleteSession = await signout();
            if (deleteSession === "success") {
              setSession(null);
              window.location.href = "/signin";
            }
          }, 3000);
        })
        .catch((error) => {
          if (error.message === "Network Error") {
            setCustomAlertInDialog(
              <Alert
                severity="error"
                className="alert"
                onClose={() => {
                  setCustomAlertInDialog("");
                }}
              >
                Network Error, Please Try Again Later!
              </Alert>
            );
          } else {
            setCustomAlertInDialog(
              <Alert
                severity="error"
                className="alert"
                onClose={() => {
                  setCustomAlertInDialog("");
                }}
              >
                {error.response.data.error}
              </Alert>
            );
          }
        });
    }
  };

  const cancelDeleteDialogMarkup = () => {
    return (
      <Dialog open={true}>
        <DialogTitle style={{ width: "350px" }}>
          Enter your password.
        </DialogTitle>
        <DialogContent style={{ width: "350px" }}>
          <div style={{ paddingBottom: "10px" }}>{customAlertInDialog}</div>
          <TextField
            autoFocus
            autoComplete="new-password"
            value={confirmPassword}
            onChange={(e) => {
              setConfirmPassword(e.target.value);
            }}
            label="Password"
            type="password"
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            onClick={() => {
              setCancelDeleteDialog("close");
              setConfirmPassword("");
              setCustomAlertInDialog("");
            }}
          >
            Cancel
          </Button>
          <Button color="primary" onClick={() => handleCancelAccountDeletion()}>
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const markedDateTime = new Date(session.markedAccountForDeletion.markedOn);
  const dateTime15DaysAfterMarkedTime = new Date(
    markedDateTime.setDate(markedDateTime.getDate() + 15)
  );

  return (
    <Grid container style={{ paddingLeft: "1%" }}>
      <Grid item xs={10} sm={8} md={6} lg={6}>
        {cancelDeleteDialog === "open" && cancelDeleteDialogMarkup()}

        {/* Logo */}
        <Box align="center">
          {/* <Link href="/"> */}
          <figure>
            <img
              src={reachship_logo}
              alt="Reachship Logo"
              className="reachship-logo-auth"
            />
            <figcaption>
              <img
                src={elextensions_caption}
                alt="elex-logo"
                className="reachship-large-logo-auth-elex-caption"
              />
            </figcaption>
          </figure>
          {/* </Link> */}
        </Box>

        <Paper sx={{ p: 3 }}>
          <Typography align="center" variant="h6" id="welcome-message">
            Your account will be deleted on{" "}
            {dateTime15DaysAfterMarkedTime.toString()}
          </Typography>
          <div className="newline" />
          <Divider />
          <div className="newline" />
          <Typography align="center" variant="body1" id="welcome-message">
            Your account is currently deactivated and scheduled for deletion. If
            you wish to cancel the deletion and reactivate your account, You can
            click the button below.
          </Typography>
          <div className="newline" />
          <Typography align="center">
            <Button
              variant="contained"
              color="primary"
              onClick={(e) => setCancelDeleteDialog("open")}
            >
              Cancel Deletion
            </Button>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <Button
              variant="outlined"
              color="primary"
              onClick={async () => {
                const deleteSession = await signout();
                if (deleteSession === "success") {
                  setSession(null);
                  window.location.href = "/signin";
                }
              }}
            >
              Sign Out
            </Button>
          </Typography>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default withRouter(DeactivatedUser);
