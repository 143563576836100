import {
  MyAccountProfileDetailsLazy,
  UserAnalyticsDashboardLazy,
  RequestLogsLazy,
  ShippingSettingsLazy,
  ApiSettingsLazy,
  BrandingSettingsLazy,
  ShipmentRecordsLazy,
  ActiveSubscriptionsLazy,
  BillingHistoryLazy,
  UpdatePaymentMethodLazy,
  CheckoutPageLazy,
  OrdersLazy,
  OrderPageLazy,
} from "../lazy-imports/userRouteImports.js";
import { Redirect } from "react-router-dom";

const FallbackRedirectShippingSettings = () => {
  return <Redirect to="/shipping-settings" />;
};

const FallbackRedirectApiSettings = () => {
  return <Redirect to="/api-settings" />;
};

export const userRoutesArray = [
  {
    path: "/orders",
    Component: OrdersLazy,
  },
  {
    path: "/view-order/:id",
    Component: OrderPageLazy,
  },
  {
    path: "/my-account",
    Component: MyAccountProfileDetailsLazy,
  },
  {
    path: "/analytics-dashboard",
    Component: UserAnalyticsDashboardLazy,
  },
  {
    path: "/request-logs",
    Component: RequestLogsLazy,
  },
  {
    path: "/shipping-settings",
    Component: ShippingSettingsLazy,
  },
  {
    path: "/api-settings",
    Component: ApiSettingsLazy,
  },
  {
    path: "/branding-settings",
    Component: BrandingSettingsLazy,
  },
  {
    path: "/shipment-records",
    Component: ShipmentRecordsLazy,
  },
  // Fallback for elex mypost plugin users, to be removed later
  {
    path: "/subscriber/shipping-settings",
    Component: FallbackRedirectShippingSettings,
  },
  {
    path: "/subscriber/api-settings",
    Component: FallbackRedirectApiSettings,
  },
  {
    path: "/subscriptions",
    Component: ActiveSubscriptionsLazy,
  },
  {
    path: "/billing-history",
    Component: BillingHistoryLazy,
  },
  {
    path: "/update-payment-method",
    Component: UpdatePaymentMethodLazy,
  },
  {
    path: "/checkout/:token",
    Component: CheckoutPageLazy,
  },
];
